import { node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectConfig } from '@ccsdk/core/ducks/config';
import { authorize, AuthorizationStatus, selectAuthorizationStatus, selectAuthorizationError, selectPermissions } from '@ccsdk/core/ducks/authentication';
import AuthorizationError from '~/components/Authentication/authorizationError';
import { withProfiler } from '@sentry/react';

export const noAuthSelector = makeSelectConfig('noAuth');

const Authentication = ({ children }) => {
  const dispatch = useDispatch();
  const noAuth = useSelector(noAuthSelector);
  const authStatus = useSelector(selectAuthorizationStatus);
  const authorizationError = useSelector(selectAuthorizationError);
  const permissions = useSelector(selectPermissions);

  // If we are in no-auth mode, just move on.
  if (noAuth === true) {
    console.info('App Shell', 'Auth', 'no-auth mode is enabled, moving on');
    return children;
  }

  if (authStatus === AuthorizationStatus.NOT_STARTED) {
    console.debug('App Shell', 'Auth', 'not logged in, initiate token renewal');
    dispatch(authorize());
    return null;
  }

  if (authStatus === AuthorizationStatus.ERROR) {
    console.error('App Shell', 'Auth', 'Authentication error encountered', authorizationError);
    return <AuthorizationError error={authorizationError} />;
  }

  if (!permissions?.length) {
    console.debug('App Shell', 'Auth', 'Waiting for permissions');
    return null;
  }

  return children;
};

Authentication.propTypes = {
  children: node,
};

export default withProfiler(Authentication, { name: 'Authentication' });
